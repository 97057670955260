import { axios } from '@/utils/request'
/**
 * 查询树类别列表
 */
export function getListTree(parameter) {
  return axios({
    url: '/fileCatalogue/listTree',
    method: 'get',
    params: parameter
  })
}
/**
 * 新增与编辑文件类别
 */
export function editFileType(parameter) {
  return axios({
    url: '/fileCatalogue/addOrEdit',
    method: 'post',
    data: parameter
  })
}
/**
 * 删除类别
 */
export function delFileType(parameter) {
  return axios({
    url: '/fileCatalogue/delete',
    method: 'post',
    data: parameter
  })
}
/**
 * 分页查询分类文件
 */
export function getFileList(parameter) {
  return axios({
    url: '/fileCatalogue/listFile',
    method: 'get',
    params: parameter
  })
}
/**
 * 共享文件
 */
export function shareFile(parameter) {
  return axios({
    url: '/fileCatalogue/shareFile',
    method: 'post',
    data: parameter
  })
}
/**
 * 新增上传文件
 */
export function addUploadFile(parameter) {
  return axios({
    url: '/fileCatalogue/addFile',
    method: 'post',
    data: parameter
  })
}
