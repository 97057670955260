<template>
  <div>
    <div class='title'>{{menuName}}</div>
    <div class='edit-form'>
      <div class='list-mana'>
        <img src='@/assets/images/fold.png' style='margin-right: 10px;'>
        <span>目录管理</span>
      </div>
      <el-form :inline='true' :rules='rules' :model='typeForm' ref="ruleForm" class='demo-form-inline'>
        <el-form-item label='分类名称' prop='name'>
          <el-input v-model.trim='typeForm.name' placeholder='请输入' clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label"><span style="margin-right: 4px; color: #f5222d; font-size: 14px">*</span>上级分类</span>
          <tree-select :data="typeTreeData"
                       :defaultProps="defaultProps"
                       :nodeKey="nodeKey"
                       :checkedKeys="defaultCheckedKeys"
                       :clearable="true"
                       @popoverHide="popoverHide"></tree-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' :loading="addLoading" @click="addType">新增类别</el-button>
          <el-button type='danger' :loading="delLoading" @click="deleteType">删除类别</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class='content'>
      <div class='tree'>
        <div v-if="treeData.length">
          <el-tree
            :data="treeData"
            v-loading="treeLoading"
            :highlight-current="true"
            :expand-on-click-node="false"
            :props='defaultProps'
            default-expand-all
            @node-click='handleNodeClick'></el-tree>
        </div>
        <div v-else>
          <el-empty description="暂无目录"></el-empty>
        </div>
      </div>
      <div class='right-table'>
        <div class='top-search'>
          <el-form :inline='true' class='table-form'>
            <el-form-item>
              <el-input v-model.trim='queryParam.name' placeholder='请输入文档名称' clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='searchForm'>查询</el-button>
            </el-form-item>
          </el-form>
          <div class='operation'>
<!--            :fileButtonIsDisabled='!nodeClickData'-->
            <uploadFile :defineTabel='false' :fileButtonIsDisabled='!nodeClickData'  @get-file-data="getFileData"/>
            <el-button class="ml-16" :disabled='!multipleSelection.length' @click="downLoadFile">下载</el-button>
            <el-button :disabled='!multipleSelection.length' type='danger' @click="handleDelete">批量删除</el-button>
          </div>
        </div>
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          stripe
          ref='table'
          size="medium"
          style='width: 100%'
          @selection-change='handleSelectionChange'>
          <el-table-column
            type='selection'
            align='center'
            width='60'>
          </el-table-column>
          <el-table-column
            type='index'
            label='序号'
            align='center'
            width='60'>
          </el-table-column>
          <el-table-column
            prop='parentName'
            label='所属类别'
            align='center'
            show-overflow-tooltip
            width='200'>
            <template slot-scope="scope">{{ scope.row.parentName || '--' }}</template>
          </el-table-column>
          <el-table-column
            prop='name'
            label='文档名称'
            align='center'
            show-overflow-tooltip
            sortable
            width='220'>
            <template slot-scope="scope">{{ scope.row.name || '--' }}</template>
          </el-table-column>
          <el-table-column
            prop='fileSuffix'
            show-overflow-tooltip
            align='center'
            width='160'
            label='文档类型'>
            <template slot-scope="scope">{{ scope.row.fileSuffix || '--' }}</template>
          </el-table-column>
          <el-table-column
            prop='createTime'
            sortable
            width="180"
            align='center'
            label='上传时间'>
            <template slot-scope="scope">{{ getMinute(scope.row.createTime) || '--' }}</template>
          </el-table-column>
          <el-table-column
            prop='createName'
            align='center'
            show-overflow-tooltip
            label='上传人'>
            <template slot-scope="scope">{{ scope.row.createName || '--' }}</template>
          </el-table-column>
          <el-table-column
            fixed='right'
            label='操作'
            align='center'
            width='140'>
            <template slot-scope='{ row }'>
              <div class="comm-operation">
                <span @click='previewFile(row)' class="edit-btn" :class="{'mr-16' : row.shareState === 1}">查看</span>
                <span v-if="row.shareState === 1" @click="handleShare(row)" class="edit-btn">共享</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class='pagination'>
          <el-pagination
            background
            :total='total'
            :current-page="queryParam.pageNo"
            :page-size="queryParam.pageSize"
            @size-change='handleSizeChange'
            @current-change='handleCurrentChange'
            :page-sizes='[10, 20, 30, 50]'
            layout='total, prev, pager, next, sizes'
            >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/utils/store';
import { debounce } from 'lodash'
import { previewFile } from '@/mixins/uploadFileOperation';
import { isImageType, isFileType, downLoadFile } from '@/assets/js/uploadFile';
import TreeSelect from '@/components/treeSelect/index.vue';
import uploadFile from '@/components/upload/UploadFile'
import { getFileList, getListTree, editFileType, delFileType, shareFile, addUploadFile } from '@/api/main/fileRelated'
export default {
  name: 'index',
  mixins: [previewFile],
  components: {
    TreeSelect, uploadFile
  },
  data() {
    return {
      menuName: '',
      // 分类表单
      typeForm: {
        name: '',
      },
      total: 0,
      queryParam: {
        name: '',
        parentId: "",
        pageNo: 1,
        pageSize: 10,
        projectId: sessionStorage.getItem('projectId'),
      },
      rules: {
        name: [{ max: 100, message: '长度限制在 100 个字符', trigger: 'blur' }]
      },
      tableData: [],
      multipleSelection: [],
      treeData: [],
      typeTreeData: [],
      treeLoading: false,
      tableLoading: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      defaultCheckedKeys: [],
      treeParam: {
        type: 2,
        parentId: "",
        projectId: sessionStorage.getItem('projectId'),
      },
      nodeKey: 'id',
      checkedData: null, // 选中的上级分类
      addLoading: false,
      delLoading: false,
      nodeClickData: null, // 选中节点
      listFile: [], // 上传文件
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.menuName = this.$route.meta.name
  },
  watch: {
    $route: {
      handler(route) {
        store.setSession('currentMenuId',route.query.id)
        this.menuName = route.meta.name
        this.resetData()
      },
      immediate: true
    }
  },
  methods: {
    getMinute(time) {
      if (!time) {
        return '--';
      }
      return time.substring(0, 16);
    },
    // 目录树结构
    getFileTree() {
      this.typeTreeData = []
      this.treeLoading = true
      getListTree(this.treeParam).then(res => {
        this.treeData = res.data
        this.typeTreeData.push(res.data[0])
      }).finally(() => {
        this.treeLoading = false
      })
    },
    // 文档分页列表
    initDataList() {
      this.tableLoading = true
      getFileList(this.queryParam).then(res => {
        this.tableData = res.data.rows
        this.total = res.data.totalRows
      }).catch(err => {
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    // 新增分类
    addType() {
      if(!this.checkedData) {
        this.$message.error('请选择上级分类')
        return
      }
      if(!this.typeForm.name) {
        this.$message.error('请输入分类名称')
        return
      }
      this.addLoading = true
      const addData = {
        projectId: sessionStorage.getItem('projectId'),
        createUser: this.userInfo.id,
        name: this.typeForm.name,
        parentId: this.checkedData.id,
        parentIds: this.checkedData.parentIds,
        type: 2,
        shareState: 1
      }
      editFileType(addData).then(res => {
        this.resetData()
        this.$message.success('新增成功')
      }).finally(() => {
        this.addLoading = false
      })
    },
    // 删除分类
    deleteType() {
      if(!this.checkedData) {
        this.$message.error('请选择上级分类')
        return
      }
      this.delLoading = true
      const delData = {
        ids: this.checkedData.id,
        menuId: this.$route.query.id || store.getSession('currentMenuId'),
      }
      delFileType(delData).then(res => {
        this.resetData()
        this.$message.success('删除成功')
      }).finally(() => {
        this.delLoading = false
      })
    },
    // 重新请求目录分类
    resetData() {
      this.queryParam = {
        name: '',
        parentId: this.$route.query.id || store.getSession('currentMenuId'),
        pageNo: 1,
        pageSize: 10,
        projectId: sessionStorage.getItem('projectId')
      }
      this.treeParam = {
        type: 2,
        parentId: this.$route.query.id || store.getSession('currentMenuId'),
        projectId: sessionStorage.getItem('projectId')
      }
      this.nodeClickData = null
      this.checkedData = null
      this.getFileTree()
      this.initDataList()
      this.typeForm.name = ''
      this.defaultCheckedKeys = []
    },
    // 搜索
    searchForm() {
      this.initDataList()
    },
    // 批量删除文件
    handleDelete() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const deleteData = {
          ids: this.multipleSelection.map(item => item.id).join(','),
          menuId: this.$route.query.id || store.getSession('currentMenuId'),
        }
        delFileType(deleteData).then(res => {
          this.initDataList()
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$refs.table.clearSelection();
      })
    },
    // 分享
    handleShare(data) {
      if(!data.fileId) {
        return
      }
      const obj = {
        id: data.id,
        projectId: data.projectId,
        projectName: data.projectName,
        name: data.name,
        fileId: data.fileId,
        parentId: this.$route.query.id || store.getSession('currentMenuId'),
        createUser: data.createUser
      }
      shareFile(obj).then(res => {
        this.getFileTree()
        this.initDataList()
        this.$message.success('共享成功')
      })
    },
    // 上传文件返回的文件 []
    getFileData(files) {
      this.tableLoading = true
      this.listFile.push({
        fileId: files.fileId,
        name: files.fileOriginName
      })
      this.addUploadFile()
    },
    addUploadFile: debounce(function(){
      const uploadData = {
        projectId: sessionStorage.getItem('projectId'),
        parentId: this.nodeClickData.id,
        parentIds: this.nodeClickData.parentIds,
        listFile: this.listFile
      }
      addUploadFile(uploadData).then(res => {
        this.initDataList()
        this.listFile = []
      })
    },3000),
    //  下载文件
    downLoadFile() {
      this.multipleSelection.map(item => {
        if(!item.fileId) {
          this.$message.error("下载失败")
          return
        }
        downLoadFile(item.fileId, item.fileOriginName);
      })
    },
    // 每页条数
    handleSizeChange(size) {
      this.queryParam.pageSize = size;
      this.queryParam.pageNo = 1;
      this.initDataList();
    },
    // 分页
    handleCurrentChange(page) {
      this.queryParam.pageNo = page;
      this.initDataList();
    },
    // 点击树节点查询文件列表
    handleNodeClick(data) {
      this.nodeClickData = data
      console.log(this.nodeClickData);
      this.queryParam.parentId = data.id
      this.initDataList()
    },
    // 选择上级分类
    popoverHide(checkedIds, checkedData) {
      this.checkedData = checkedData
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  }
};
</script>

<style lang='less' scoped>
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
}
.edit-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  border: 1px solid #E8E8E8;
  border-left: none;
  border-right: none;
}
.demo-form-inline {
  padding: 17px 0 0 50px;
}
.list-mana {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.operation{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.content {
  padding: 16px;
  .right-table {
    overflow: hidden;
    .top-search {
      display: flex;
      justify-content: space-between;
    }
  }
}
/deep/.el-tree{
  .el-tree-node__content{
    display: block!important;
  }
  .el-tree-node__children{
    overflow: visible!important;
  }
}
.tree {
  width: 180px;
  float: left;
  border: 1px solid #DCDFE7;
  margin-right: 16px;
  padding: 12px 12px 12px 0;
  //height: 300px;
  min-height: calc(100vh - 300px);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  .tree-title {
    padding-bottom: 26px;
  }
}
.pagination {
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #F3F6FE;
  color: #117BF7;
}
</style>
